/*
https://github.com/rsuite/rsuite/blob/next/src/styles/variables.less
@tooltip-max-width:             250px;
@tooltip-opacity:               1;
@tooltip-border-radius:         4px;
@tooltip-arrow-width:           6px;
@tooltip-translate-distance:    2px;

// Distance between Arrow and frame.
@tooltip-arrow-gap-lg:          10px;
@tooltip-arrow-gap-sm:          4px;
*/

/* Base class */
.rs-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  opacity: 0;
  line-height: 1.6;
  max-width: 250px;
  padding: 2px 10px;
  color: var(--rs-tooltip-text, #fff);
  text-align: center;
  background-color: var(--rs-tooltip-bg, #007ac4);
  border-radius: 4px;
  overflow-wrap: break-word;
}
.rs-tooltip.fade {
  transition: opacity 0.1s linear, transform 0.1s ease-out;
}

.rs-tooltip.in {
  opacity: 1;
  transition: opacity 0.15s linear, transform 0.15s ease-in;
}

/* Arrows */
.rs-tooltip::after {
  content: " ";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

/* Common style for .placement-top* */
.rs-tooltip[class*="placement-top"] {
  margin-top: -8px;
  transform: translate(0, 0);
}
.rs-tooltip[class*="placement-top"].in {
  transform: translate(0, 2px);
}
.rs-tooltip[class*="placement-top"]::after {
  bottom: -6px;
  margin-left: -6px;
  border-width: 6px 6px 0;
  border-top-color: var(--rs-tooltip-bg, #007ac4);
}
/* FIXED: The problem of the  1 pixel white border in Edge browser .*/
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-top"]::after {
    bottom: -5px;
  }
}

/* Common style for .placement-bottom* */
.rs-tooltip[class*="placement-bottom"] {
  margin-top: 8px;
  transform: translate(0, 0);
}
.rs-tooltip[class*="placement-bottom"].in {
  transform: translate(0, 2px);
}
.rs-tooltip[class*="placement-bottom"]::after {
  top: -6px;
  margin-left: -6px;
  border-width: 0 6px 6px;
  border-bottom-color: var(--rs-tooltip-bg, #007ac4);
}
/* FIXED: The problem of the  1 pixel white border in Edge browser .*/
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-bottom"]::after {
    top: -5px;
  }
}

/* Common style for .placement-right* */
.rs-tooltip[class*="placement-right"] {
  margin-left: 8px;
  transform: translate(0, 0);
}
.rs-tooltip[class*="placement-right"].in {
  transform: translate(2px, 0);
}
.rs-tooltip[class*="placement-right"]::after {
  left: -6px;
  margin-top: -6px;
  border-width: 6px 6px 6px 0;
  border-right-color: var(--rs-tooltip-bg, #007ac4);
}
/* FIXED: The problem of the  1 pixel white border in Edge browser .*/
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-right"]::after {
    left: -5px;
  }
}

/* Common style for .placement-lef* */
.rs-tooltip[class*="placement-left"] {
  margin-left: -8px;
  transform: translate(0, 0);
}
.rs-tooltip[class*="placement-left"].in {
  transform: translate(-2px, 0);
}
.rs-tooltip[class*="placement-left"]::after {
  right: -6px;
  margin-top: -6px;
  border-width: 6px 0 6px 6px;
  border-left-color: var(--rs-tooltip-bg, #007ac4);
}
/* FIXED: The problem of the  1 pixel white border in Edge browser .*/
@supports (-ms-ime-align: auto) {
  .rs-tooltip[class*="placement-left"]::after {
    right: -5px;
  }
}

.rs-tooltip.placement-bottom::after,
.rs-tooltip.placement-top::after {
  left: 50%;
}

.rs-tooltip.placement-bottom-start::after,
.rs-tooltip.placement-top-start::after {
  left: 10px;
}

.rs-tooltip.placement-bottom-end::after,
.rs-tooltip.placement-top-end::after {
  right: 4px;
}

.rs-tooltip.placement-right::after,
.rs-tooltip.placement-left::after {
  top: 50%;
}

.rs-tooltip.placement-right-start::after,
.rs-tooltip.placement-left-start::after {
  top: 10px;
}

.rs-tooltip.placement-right-end::after,
.rs-tooltip.placement-left-end::after {
  bottom: 4px;
}

.tooltip-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  color: inherit;
  background: none;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  appearance: none;
  padding: 0;
}
