.loading {
  min-height: 320px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loading > div {
  width: 100%;
  text-align: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

/* CSS of loader was taken from https://github.com/lukehaas/css-loaders */
.loading-item,
.loading-item:before,
.loading-item:after {
  background: #007ac4;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loading-item {
  color: #007ac4;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loading-item:before,
.loading-item:after {
  position: absolute;
  top: 0;
  content: "";
}
.loading-item:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading-item:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
