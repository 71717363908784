/* Overwrite styles from kodexe and global styles */

.holder-of-core-selection-guide-columns-v2 .column,
.holder-of-finishing-guide-columns .column {
  align-content: flex-start;
}

.holder-of-core-selection-guide-columns-v2 .products-holder,
.holder-of-finishing-guide-columns .products-holder {
  display: none;
}

@media screen and (max-width: 668px) {
  .holder-of-core-selection-guide-columns-v2 .products-holder,
  .holder-of-finishing-guide-columns .products-holder {
    display: block;
  }
}

@media screen and (max-width: 668px) {
  .holder-of-core-selection-guide-columns-v2 .column .button-mobile .cancel {
    background-color: transparent;
    border: none;
    display: inline-block;
  }
}

button.button {
  background-color: #ef7c00;
  color: #fff;
  padding: 20px 30px;
  margin-top: 40px;
  display: inline-block;
  border: 1px solid #ef7c00;
}
button.no-button {
  background: none;
  border: none;
  padding: 5px;
}
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
button {
  cursor: pointer;
}

.holder-of-core-selection-guide-columns-v2
  .filter-holder
  .filter-group.active
  .slider,
.holder-of-finishing-guide-columns .filter-holder .filter-group.active .slider {
  display: none;
}
